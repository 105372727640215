.home_main{
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.school_name{
  font-size: 50px;
  font-weight: 700;
}
.landing_video{
  /* height: 100vh !important; */
  object-fit: contain !important;
  margin-top: 9%;
}
.faq-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  margin-top: 2rem !important;
  padding: 1rem !important;
}

.faq-heading {
  color: #6c5244 !important;
  margin-bottom: 0.5rem !important;
  
}
.faq-subheading {
  color: #6c5244 !important;
  margin-bottom: 1rem !important;
}
.faq-accordion {
  background-color: lightblue !important;
  border-radius: 10px !important;
  margin-bottom: 1rem !important;
  width: 100% !important;
}

.faq-header {
  background-color: transparent !important;
  border-radius: 10px !important;
  display: flex !important;
  align-items: center !important;
}

.faq-icon {
  margin-right: 0.5rem !important;
  color: #6c5244 !important;
}

.faq-question {
  font-size: 1.25rem !important;
  color: #6c5244 !important;
}

.faq-body {
  background-color: #fbf7f2 !important;
  border-radius: 10px !important;
  padding: 1rem !important;
}

.faq-answer {
  font-size: 1rem !important;
  color: #6c5244 !important;
  white-space: pre-line;
}


.heading-small
{
  color: #0076bd;
  
  text-align: center;
  margin-top: 20px;
  font-size: 20px;
  margin-left: 10%;
  margin-right: 10%;
  
}
.landing_video> video{
  object-fit: contain;
}
@media(max-width:640px){
  .landing_video{
    height: auto !important;
    margin-top: 4rem;
  }
  .school_name{
    font-size: 25px;
    font-weight: 700;
    margin-left: 5px;
  }
  .heading-small{
    font-size: 10px;
  }
}