*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none !important;
  color: black !important;
}


.blog {
  margin-top: 150px;
  display: grid;
  gap: 20px;
  padding: 20px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.blog-post {
  background-color: #fff;
  border: 1px solid blue;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.blog-post-content {

  overflow: hidden;
  text-overflow: ellipsis;
}

.read-more{
  color: blue !important;
}