.footer_main {
  display: flex;
  padding: 2rem 3rem;
  gap: 2rem;
  /* margin-top: 2rem; */
  background-color: #f8f8f9;
  flex-wrap: wrap;
}
.footer{
  margin-left: 6rem;
}
.footer-section {
  text-align: center;
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
}
.footer-section-1,
.footer-section-2,
.footer-section-3 {
  display: flex;
  flex-direction: column;
  
}
.footer-logo {
  margin-top: -1rem;
  border-right: 1px solid gray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 640px) {
  .footer_main {
    padding-left: 0;
  }
  .footer-section {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: small;
  }
  .footer{
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .footer-logo{
    display: flex;
    justify-content: flex-start;
  }
  .footer_logo{
    width: 30vw;
  }

}
