.reg_main{
    padding: 2rem 4rem;
}
.reg_main > h2{
    font-weight: 400;
    font-size: 2.5rem;
    color: #3a3a3a;
}
.form_cont{
    display: flex;
    padding: 1rem 5rem;
    gap: 1.5rem;
    flex-wrap: wrap;
    justify-content: center;
}
.input-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    width: 100%;
    border: 1px solid gray;
    padding: 0.5rem 1rem;
    width: 300px;
    max-height: 62px;
    position: relative;
}
@media (max-width: 640px){
    .form_cont {
        padding: 0;
    }
}